import { FC, useMemo } from "react";

import { CmsImage, CmsLink, Html } from "@/components/elements";
import { CmsImageData } from "@/types/cms-image-data";
import { getGatsbyImageData } from "@/utils/get-gatsby-image-data";
import { mapJustify } from "@/utils/map-justify";

import * as Styles from "../home.module.css";

export interface LinkCardProps {
  label: string;
  url: string;
  headingColor: string;
  color: string;
  bgColor: string;
  textAlignment: "left" | "center" | "right";
  bgImg?: CmsImageData;
  textMd?: string;
}

const LinkCard: FC<LinkCardProps> = ({
  url,
  bgColor,
  label,
  bgImg,
  textMd,
  headingColor,
  textAlignment,
  color,
}: LinkCardProps): JSX.Element => {
  const { gatsbyBgImg } = useMemo(() => {
    return {
      gatsbyBgImg: getGatsbyImageData(bgImg),
    };
  }, [bgImg]);

  const contentAlign = useMemo(() => mapJustify(textAlignment), [textAlignment]);

  return (
    <CmsLink className={"group relative"} title={label} href={url} label={label}>
      <div
        style={{
          backgroundColor: bgColor,
          justifyContent: contentAlign,
          padding: contentAlign === "center" ? "0" : "0 10px",
        }}
        className={"h-[4.5em] w-[23.5em] absolute flex items-center z-10"}
      >
        <label className={"font-bold text-[1.5em]"} style={{ color: headingColor }}>
          {label}
        </label>
      </div>
      <div className={"h-[30em] relative overflow-hidden"}>
        {!bgImg && (
          <div
            className={"h-full flex items-center px-5"}
            style={{
              backgroundColor: bgColor,
              justifyContent: contentAlign,
              padding: contentAlign === "center" ? "0" : "0 10px",
            }}
          >
            <Html className={"text-xl"} style={{ color }}>
              {textMd}
            </Html>
          </div>
        )}
        {bgImg && textMd && (
          <>
            <CmsImage
              className={
                "h-full w-full object-cover transition-transform duration-500 ease-in-out group-hover:scale-[1.035]"
              }
              image={gatsbyBgImg!}
              alt={label}
            />
            <div
              style={{ backgroundColor: bgColor }}
              className={`${Styles.textContainer} opacity-75 absolute bottom-0 w-full flex justify-center items-center`}
            />
            <div
              className={`${Styles.textContainer} absolute bottom-0 w-full flex justify-center items-center p-3`}
            >
              <Html
                noStyle
                as={"span"}
                className={"text-[0.9em] leading-[19px]"}
                style={{
                  color,
                  textAlign: textAlignment,
                }}
              >
                {textMd}
              </Html>
            </div>
          </>
        )}
      </div>
    </CmsLink>
  );
};

export default LinkCard;
